export const words = [
  '伤心桥下春波绿',
  '曾是惊鸿照影来',
  '当年明月在',
  '曾照彩云归',
  '归去来兮',
  '真堪偕隐',
  '画船听雨眠',
  '愿为江水',
  '与君重逢',
  '一日不见兮',
  '思之若狂',
  '好想回到那个夏天',
  // '趴在桌子上偷偷看你',
  // '你是我灰色人生中的一道彩虹',
  // '柳絮空缱绻',
  '南风知不知',
  '我见青山多妩媚',
  '料青山见我也应如是',
  '取次花丛懒回顾',
  '半缘修道半缘君',
  // '三笑徒然当一痴',
  '人生若只如初见',
  '我余光中都是你',
  '人生自是有情痴',
  // '此恨不关风与月',
  '因为你，我多少适应了这个世界',
  '春蚕到死丝方尽',
  '蜡炬成灰泪始干',
  '今夜何夕',
  '见此良人',
  '愿我如星君如月',
  '夜夜流光相皎洁',
  '情不所起',
   '一往而深',
  // '玲珑骰子安红豆',
  // '入骨相思知不知',
  // '多情只有春庭月',
  // '尤为离人照落花',
  // '若有知音见采',
  // '不辞唱遍阳春',
  // '休言半纸无多重',
  // '万斛离愁尽耐担',
  '倾盖如故',
  '桂花浅深处',
  '一去紫台连朔漠',
  '死生契阔',
  '凝绝不通声暂歇',
  '春水碧于天',
  '盈盈一水间',
  '夜月一帘幽梦',
  '美人如花隔云端',
  '独倚望江楼',
  '五月渔郎相忆否',
  '起舞弄清影',
  '和光同尘',
  '杳霭流玉',
  '月落星沉',
  '霞姿月韵',
  '喜上眉梢',
  '醉后不知天在水',
  '满船星梦压星河',
  '落花人独立',
  '微雨燕双飞',
  '掬水月在手',
  '弄花香满衣',
  '夜深忽梦少年事',
  '唯梦闲人不梦君',
  '垆边人似月',
  '皓腕凝霜雪',
  '众里嫣然通一顾',
  '人间颜色如尘土',
  '若非群玉山头见',
  '会向瑶台月下逢',
  '沉鱼落雁鸟惊喧',
  '羞花闭月花愁颤',
  '解释春风无限恨',
  '沉香亭北倚阑干'
];

export const wordsToPhone = [
  '曾是惊鸿照影来',
  '当年明月在',
  '曾照彩云归',
  '归去来兮',
  '人生若只如初见',
  '我余光中都是你',
  '夜夜流光相皎洁',
  '倾盖如故',
  '桂花浅深处',
  '一去紫台连朔漠',
  '死生契阔',
  '凝绝不通声暂歇',
  '春水碧于天',
  '盈盈一水间',
  '夜月一帘幽梦',
  '美人如花隔云端',
  '独倚望江楼',
  '五月渔郎相忆否',
  '起舞弄清影',
  '和光同尘',
  '杳霭流玉',
];