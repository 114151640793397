import React from 'react'
import ReactDOM from 'react-dom/client';
import Qixi from './App'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Qixi></Qixi>
  </React.StrictMode>,
);
